export const fr = {
    SOM000028: "L’information sur le compte ne peut pas être affichée pour l’instant. Réessayez plus tard. (SOM000028)",
    SOM000029: "L’information sur le compte ne peut pas être affichée pour l’instant. Réessayez plus tard. (SOM000029)",
    SOM000034: "L’information sur les comptes de carte de crédit Desjardins ne peut pas être affichée pour l’instant. Réessayez plus tard. (SOM000034)",
    SOM000035: "L’information sur les comptes de carte de crédit Desjardins ne peut pas être affichée pour l’instant. Réessayez plus tard. (SOM000035)",
    SOM000067: "Votre transaction peut avoir été annulée par l’utilisation du bouton Précédent ou Suivant du navigateur. Vérifiez que votre transaction a bien été faite. (SOM000067)",
    SOM000086: "L’information sur les comptes Valeurs mobilières Desjardins ne peut pas être affichée pour l’instant. Réessayez plus tard. (SOM000086)",
    SOM000087: "L’information sur les comptes Disnat ne peut pas être affichée pour l’instant. Réessayez plus tard. (SOM000087)",
    SOM000088: "L’information sur les comptes Placements stratégiques ne peut pas être affichée pour l’instant. Réessayez plus tard. (SOM000088)",
    SOM000089: "L’information sur les comptes Valeurs mobilières Desjardins ne peut pas être affichée pour l’instant. Réessayez plus tard. (SOM000089)",
    SOM000090: "L’information sur les comptes Disnat ne peut pas être affichée pour l’instant. Réessayez plus tard. (SOM000090)",
    SOM000091: "L’information sur les comptes Régimes collectifs ne peut pas être affichée pour l’instant. Réessayez plus tard. (SOM000091)",
    SOM000092: "L’information sur les comptes Placements stratégiques ne peut pas être affichée pour l’instant. Réessayez plus tard. (SOM000092)",
    SOM000093: "L’information sur le compte Capital régional et coopératif Desjardins ne peut pas être affichée pour l’instant. Réessayez plus tard. (SOM000093)",
    SOM000094: "L’information sur les comptes Parts de capital ne peut pas être affichée pour l’instant. Réessayez plus tard. (SOM000094)",
    SOM000095: "L’information sur les comptes de régime enregistré d’épargne-étude (REEE) ne peut pas être affichée pour l’instant. Réessayez plus tard. (SOM000095)",
    SOM000096: "L’information sur les comptes Gestion privée Desjardins ne peut pas être affichée pour l’instant. Réessayez plus tard. (SOM000096)",
    SOM000103: "La fonction Suivis et sauvegardes n’est pas disponible pour l’instant. Réessayez plus tard. (SOM000103)",
    "com.desjardins.sommaire_perso.detail.carte_credit.payer_la_carte": "Une erreur s'est produite. Réessayez plus tard. Si le problème persiste, communiquez avec nous.",
    "com.desjardins.sommaire_perso.visa.switch.inactif": "Cette fonction n'est pas disponible pour le moment. Réessayer plus tard.",
    HDS000901: "Cette fonction n’est pas disponible pour l’instant. Réessayez plus tard. (HDS000901)",
    HDS000902: "L’information de certains comptes ne peut pas être affichée pour l’instant. Réessayez plus tard. (HDS000902)",
    "com.desjardins.sommaire_affaires.detention.site_externe.erreur.can_act.": "La fonction Remises gouvernementales – Can-Act n’est pas disponible pour l’instant. Réessayez plus tard. (ACC-900)",
    "com.desjardins.sommaire_affaires.detention.site_externe.erreur.can_act.4001": "La fonction Remises gouvernementales – Can-Act n’est pas disponible pour l’instant. Réessayez plus tard. (ACC-901)",
    "com.desjardins.sommaire_affaires.detention.site_externe.erreur.can_act.4002": "La fonction Remises gouvernementales – Can-Act n’est pas disponible pour l’instant. Réessayez plus tard. (ACC-901)",
    "com.desjardins.sommaire_affaires.detention.site_externe.erreur.can_act.4003": "La fonction Remises gouvernementales – Can-Act n’est pas disponible pour l’instant. Réessayez plus tard. (ACC-901)",
    "com.desjardins.sommaire_affaires.detention.site_externe.erreur.can_act.4004": "La fonction Remises gouvernementales – Can-Act n’est pas disponible pour l’instant. Réessayez plus tard. (ACC-901)",
    "com.desjardins.sommaire_affaires.detention.site_externe.erreur.can_act.7000": "La fonction Remises gouvernementales – Can-Act n’est pas disponible pour l’instant. Réessayez plus tard. (ACC-901)",
    "com.desjardins.sommaire_affaires.detention.site_externe.erreur.can_act.7003": "La fonction Remises gouvernementales – Can-Act n’est pas disponible pour l’instant. Réessayez plus tard. (ACC-901)",
    "com.desjardins.sommaire_affaires.detention.site_externe.erreur.can_act.7100": "La fonction Remises gouvernementales – Can-Act n’est pas disponible pour l’instant. Réessayez plus tard. (ACC-901)",
    "com.desjardins.sommaire_affaires.detention.site_externe.erreur.can_act.7200": "La fonction Remises gouvernementales – Can-Act n’est pas disponible pour l’instant. Réessayez plus tard. (ACC-901)",
    "com.desjardins.sommaire_affaires.detention.site_externe.erreur.can_act.7300": "La fonction Remises gouvernementales – Can-Act n’est pas disponible pour l’instant. Réessayez plus tard. (ACC-901)",
    "com.desjardins.sommaire_affaires.detention.site_externe.erreur.contrat_change.": "La fonction Contrat de change n’est pas disponible pour l’instant. Réessayez plus tard. (ACC-902)",
    "com.desjardins.sommaire_affaires.detention.site_externe.erreur.contrat_change.CLO001": "Votre profil d’utilisateur ne vous permet pas d’accéder à la fonction Contrat de change. (ACC-903)",
    "com.desjardins.sommaire_affaires.detention.site_externe.erreur.contrat_change.CLO002": "Votre profil d’utilisateur ne vous permet pas d’accéder à la fonction Contrat de change. (ACC-903)",
    "com.desjardins.sommaire_affaires.detention.site_externe.erreur.contrat_change.CLO003": "Votre profil d’utilisateur ne vous permet pas d’accéder à la fonction Contrat de change. (ACC-903)",
    "com.desjardins.sommaire_affaires.detention.site_externe.erreur.contrat_change.CLO004": "Votre profil d’utilisateur ne vous permet pas d’accéder à la fonction Contrat de change. (ACC-903)",
    "com.desjardins.sommaire_affaires.detention.site_externe.erreur.contrat_change.CLO005": "Votre profil d’utilisateur ne vous permet pas d’accéder à la fonction Contrat de change. (ACC-903)",
    "com.desjardins.sommaire_affaires.detention.site_externe.erreur.contrat_change.CLO006": "Votre profil d’utilisateur ne vous permet pas d’accéder à la fonction Contrat de change. (ACC-903)",
    "com.desjardins.sommaire_affaires.detention.site_externe.erreur.contrat_change.CLO007": "Votre profil d’utilisateur ne vous permet pas d’accéder à la fonction Contrat de change. (ACC-903)",
    "com.desjardins.sommaire_affaires.detention.site_externe.erreur.contrat_change.CLO008": "Votre profil d’utilisateur ne vous permet pas d’accéder à la fonction Contrat de change. (ACC-903)",
    "com.desjardins.sommaire_affaires.detention.site_externe.erreur.contrat_change.CLO009": "Votre profil d’utilisateur ne vous permet pas d’accéder à la fonction Contrat de change. (ACC-903)",
    "com.desjardins.sommaire_affaires.detention.site_externe.erreur.contrat_change.CLO010": "Votre profil d’utilisateur ne vous permet pas d’accéder à la fonction Contrat de change. (ACC-903)",
    "com.desjardins.sommaire_affaires.detention.site_externe.erreur.contrat_change.CLO011": "Votre profil d’utilisateur ne vous permet pas d’accéder à la fonction Contrat de change. (ACC-903)",
    "com.desjardins.sommaire_affaires.detention.site_externe.erreur.contrat_change.CLO012": "Votre profil d’utilisateur ne vous permet pas d’accéder à la fonction Contrat de change. (ACC-903)",
    "com.desjardins.sommaire_affaires.detention.site_externe.erreur.mgps.": "La fonction Solution globale de paiements Desjardins n’est pas disponible pour l’instant. Réessayez plus tard. (ACC-904)",
    "com.desjardins.sommaire_affaires.detention.site_externe.erreur.mgps.POR.000100": "La fonction Solution globale de paiements Desjardins n’est pas disponible pour l’instant. Réessayez plus tard. (ACC-905)",
    "com.desjardins.sommaire_affaires.detention.site_externe.erreur.mgps.POR.000101": "La fonction Solution globale de paiements Desjardins n’est pas disponible pour l’instant. Réessayez plus tard. (ACC-906)",
    "com.desjardins.sommaire_affaires.detention.site_externe.erreur.mgps.POR.000102": "La fonction Solution globale de paiements Desjardins n’est pas disponible pour l’instant. Réessayez plus tard. (ACC-907)",
    "com.desjardins.sommaire_affaires.detention.site_externe.erreur.mgps.POR.000103": "La fonction Solution globale de paiements Desjardins n’est pas disponible pour l’instant. Réessayez plus tard. (ACC-908)",
    "com.desjardins.sommaire_affaires.detention.site_externe.erreur.mgps.POR.000104": "La fonction Solution globale de paiements Desjardins n’est pas disponible pour l’instant. Réessayez plus tard. (ACC-909)",
    "com.desjardins.sommaire_affaires.detention.site_externe.erreur.mgps.POR.000105": "La fonction Solution globale de paiements Desjardins n’est pas disponible pour l’instant. Réessayez plus tard. (ACC-910)",
    "com.desjardins.sommaire_affaires.detention.scanneur_depot_distance.ncr.sso.compte.nonexistant": "Votre profil d’utilisateur ne vous permet pas d’accéder à la fonction Dépôt à distance scanneur. (ACC-911)",
    "com.desjardins.sommaire_affaires.detail.dernieres_operations.erreur_chargement": "La fonction Dépôt à distance scanneur n’est pas disponible pour l’instant. Réessayez plus tard. (ACC-912)",
    GENERIC: "Une erreur s'est produite. Réessayez plus tard. Si le problème persiste, communiquez avec nous."
};
