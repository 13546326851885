import * as bc from "bc-js";
import * as configuration from "@oel/js-configuration";
import * as navigation from "@oel/js-navigation";

import { Alerte } from "../../commun/Alerte/Alerte";
import { BanniereRistourne } from "./ristourne/BanniereRistourne";
import { GabaritNavigationUtil } from "../../../utils/GabaritNavigationUtil";
import { LangueUtils } from "@oel/js-i18n";
import { ResilienceUtil } from "../../../utils/ResilienceUtil";
import { en } from "./i18n/AccueilParticulier.i18n.en";
import { fr } from "./i18n/AccueilParticulier.i18n.fr";
import { useEffect } from 'react';

export interface AccueilParticulierProps {
    estSessionLegacyCharge: boolean
}

export function AccueilParticulier(props: AccueilParticulierProps) {
    let libelles = new LangueUtils(fr, en).obtenirLibelles();

    useEffect(() => {
        document.title = libelles.titreOnglet;

        ResilienceUtil.rendreResilientSiProbleme("bc", () => bc.changerContexte("PRODUCT_Summary"));
        gererNavInitial();

        GabaritNavigationUtil.afficherMenuDroite(true);
        GabaritNavigationUtil.afficherTiroirPrimeurs(true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    
    function gererNavInitial() {
        const windowLocationSearch = window.location.search;
        const queryParams = new URLSearchParams(windowLocationSearch);
        const paramNavInitial = queryParams.get("nav_initial");
        const urlCoteCredit = "/dossier-personnel/score-credit/consentement";

        // Condition temporaire pour avant l'ouverture de la Split ADP_BureauxCredit_NouveauParcours au courant de l'été 2023
        if (paramNavInitial === "onglet-code-credit") {
            window.open(configuration.ConfigurationService.getConfig("oel-adpm-url") + urlCoteCredit);
            retirerQueryParams();
        } else {
            // Gestion d'ouverture automatique de modale
            navigation.ModaleService.gererOuvertureModale(windowLocationSearch);
        }
    }

    function retirerQueryParams() {
        const urlComplet = window.location.href;
        const urlSansParams = urlComplet.split('?')[0];
        window.history.replaceState({}, '', urlSansParams);
    }

    return (
        <div className="mes-comptes">
            <h1 className="dsd-sr-only" tabIndex={-1}>{libelles.titre}</h1>
            <Alerte/>
            <accueil-particulier est-session-legacy-charge={props.estSessionLegacyCharge}>                
                <div slot="banniere-ristourne">
                    {configuration.ConfigurationService.getConfig("oel-obtenir-ristourne") === "true" && props.estSessionLegacyCharge &&
                        <BanniereRistourne />
                    }
                </div>
                
                <div slot="conseils-personnalises">
                    {props.estSessionLegacyCharge &&
                        <conseils-personnalises className="conseil-perso" service-id="sommaireADP_detention_afficher" canal="web" spa="true"/>
                    }
                </div>
            </accueil-particulier>
        </div>
    );
}
