import { GabaritNavigationUtil } from "../../../utils/GabaritNavigationUtil";
import { LangueUtils } from "@oel/js-i18n";
import { en } from "./i18n/DetailsCompteBancaire.i18n.en";
import { fr } from "./i18n/DetailsCompteBancaire.i18n.fr";
import { useParams } from 'react-router';
import { useEffect } from "react";

export function DetailsCompteBancaire() {

    const params = useParams();
    const libelles = new LangueUtils(fr, en).obtenirLibelles();
    
    useEffect(() => {
        document.title = libelles.titreOnglet;
        GabaritNavigationUtil.afficherMenuDroite(false);
        GabaritNavigationUtil.afficherTiroirPrimeurs(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    
    return (
        <div>
            <detail-compte-bancaire id-contexte={params.idContexte}/>
        </div>
    );
}
