export const en = {
    SOM000028: "Account information can't currently be displayed. Try again later. (SOM000028)",
    SOM000029: "Account information can't currently be displayed. Try again later. (SOM000029)",
    SOM000034: "Information on Desjardins credit card accounts can't currently be displayed. Try again later. (SOM000034)",
    SOM000035: "Information on Desjardins credit card accounts can't currently be displayed. Try again later. (SOM000035)",
    SOM000067: "You may have cancelled your transaction by using your browser's Back or Forward button. Check to see if the transaction has been made. (SOM000067)",
    SOM000086: "Information on Desjardins Securities accounts can't currently be displayed. Try again later. (SOM000086)",
    SOM000087: "Information on Disnat accounts can't currently be displayed. Try again later. (SOM000087)",
    SOM000088: "Information on Strategic Investment accounts can't currently be displayed. Try again later. (SOM000088)",
    SOM000089: "Information on Desjardins Securities accounts can't currently be displayed. Try again later. (SOM000089)",
    SOM000090: "Information on Disnat accounts can't currently be displayed. Try again later. (SOM000090)",
    SOM000091: "Information on Group Plan accounts can't currently be displayed. Try again later. (SOM000091)",
    SOM000092: "Information on Strategic Investment accounts can't currently be displayed. Try again later. (SOM000092)",
    SOM000093: "Information on Capital régional et coopératif Desjardins accounts can't currently be displayed. Try again later. (SOM000093)",
    SOM000094: "Information on Capital Share accounts can't currently be displayed. Try again later. (SOM000094)",
    SOM000095: "Information on Registered Education Savings Plan (RESP) accounts can't currently be displayed. Try again later. (SOM000095)",
    SOM000096: "Information on Desjardins Private Wealth Management accounts can't currently be displayed. Try again later. (SOM000096)",
    SOM000103: "The Follow-ups and saved documents feature is currently unavailable. Try again later. (SOM0000103)",
    "com.desjardins.sommaire_perso.detail.carte_credit.payer_la_carte": "An error has occurred. Try again later. If the issue persists, contact us.",
    "com.desjardins.sommaire_perso.visa.switch.inactif": "This function is not available at the moment. Please try again later.",
    HDS000901: "This feature is currently unavailable. Try again later. (HDS000901)",
    HDS000902: "Some of your account details cannot currently be displayed. Try again later. (HDS000902)",
    "com.desjardins.sommaire_affaires.detention.site_externe.erreur.can_act.": "Government Remittances – Can-Act is currently unavailable. Try again later. (ACC-900)",
    "com.desjardins.sommaire_affaires.detention.site_externe.erreur.can_act.4001": "Government Remittances – Can-Act is currently unavailable. Try again later. (ACC-901)",
    "com.desjardins.sommaire_affaires.detention.site_externe.erreur.can_act.4002": "Government Remittances – Can-Act is currently unavailable. Try again later. (ACC-901)",
    "com.desjardins.sommaire_affaires.detention.site_externe.erreur.can_act.4003": "Government Remittances – Can-Act is currently unavailable. Try again later. (ACC-901)",
    "com.desjardins.sommaire_affaires.detention.site_externe.erreur.can_act.4004": "Government Remittances – Can-Act is currently unavailable. Try again later. (ACC-901)",
    "com.desjardins.sommaire_affaires.detention.site_externe.erreur.can_act.7000": "Government Remittances – Can-Act is currently unavailable. Try again later. (ACC-901)",
    "com.desjardins.sommaire_affaires.detention.site_externe.erreur.can_act.7003": "Government Remittances – Can-Act is currently unavailable. Try again later. (ACC-901)",
    "com.desjardins.sommaire_affaires.detention.site_externe.erreur.can_act.7100": "Government Remittances – Can-Act is currently unavailable. Try again later. (ACC-901)",
    "com.desjardins.sommaire_affaires.detention.site_externe.erreur.can_act.7200": "Government Remittances – Can-Act is currently unavailable. Try again later. (ACC-901)",
    "com.desjardins.sommaire_affaires.detention.site_externe.erreur.can_act.7300": "Government Remittances – Can-Act is currently unavailable. Try again later. (ACC-901)",
    "com.desjardins.sommaire_affaires.detention.site_externe.erreur.contrat_change.": "The Foreign Exchange Contracts feature is currently unavailable. Try again later. (ACC-902)",
    "com.desjardins.sommaire_affaires.detention.site_externe.erreur.contrat_change.CLO001": "This user profile does not have permission to use Foreign Exchange Contracts. (ACC-903)",
    "com.desjardins.sommaire_affaires.detention.site_externe.erreur.contrat_change.CLO002": "This user profile does not have permission to use Foreign Exchange Contracts. (ACC-903)",
    "com.desjardins.sommaire_affaires.detention.site_externe.erreur.contrat_change.CLO003": "This user profile does not have permission to use Foreign Exchange Contracts. (ACC-903)",
    "com.desjardins.sommaire_affaires.detention.site_externe.erreur.contrat_change.CLO004": "This user profile does not have permission to use Foreign Exchange Contracts. (ACC-903)",
    "com.desjardins.sommaire_affaires.detention.site_externe.erreur.contrat_change.CLO005": "This user profile does not have permission to use Foreign Exchange Contracts. (ACC-903)",
    "com.desjardins.sommaire_affaires.detention.site_externe.erreur.contrat_change.CLO006": "This user profile does not have permission to use Foreign Exchange Contracts. (ACC-903)",
    "com.desjardins.sommaire_affaires.detention.site_externe.erreur.contrat_change.CLO007": "This user profile does not have permission to use Foreign Exchange Contracts. (ACC-903)",
    "com.desjardins.sommaire_affaires.detention.site_externe.erreur.contrat_change.CLO008": "This user profile does not have permission to use Foreign Exchange Contracts. (ACC-903)",
    "com.desjardins.sommaire_affaires.detention.site_externe.erreur.contrat_change.CLO009": "This user profile does not have permission to use Foreign Exchange Contracts. (ACC-903)",
    "com.desjardins.sommaire_affaires.detention.site_externe.erreur.contrat_change.CLO010": "This user profile does not have permission to use Foreign Exchange Contracts. (ACC-903)",
    "com.desjardins.sommaire_affaires.detention.site_externe.erreur.contrat_change.CLO011": "This user profile does not have permission to use Foreign Exchange Contracts. (ACC-903)",
    "com.desjardins.sommaire_affaires.detention.site_externe.erreur.contrat_change.CLO012": "This user profile does not have permission to use Foreign Exchange Contracts. (ACC-903)",
    "com.desjardins.sommaire_affaires.detention.site_externe.erreur.mgps.": "Desjardins Global Solution for Payments is currently unavailable. Try again later. (ACC-904)",
    "com.desjardins.sommaire_affaires.detention.site_externe.erreur.mgps.POR.000100": "Desjardins Global Solution for Payments is currently unavailable. Try again later. (ACC-905)",
    "com.desjardins.sommaire_affaires.detention.site_externe.erreur.mgps.POR.000101": "Desjardins Global Solution for Payments is currently unavailable. Try again later. (ACC-906)",
    "com.desjardins.sommaire_affaires.detention.site_externe.erreur.mgps.POR.000102": "Desjardins Global Solution for Payments is currently unavailable. Try again later. (ACC-907)",
    "com.desjardins.sommaire_affaires.detention.site_externe.erreur.mgps.POR.000103": "Desjardins Global Solution for Payments is currently unavailable. Try again later. (ACC-908)",
    "com.desjardins.sommaire_affaires.detention.site_externe.erreur.mgps.POR.000104": "Desjardins Global Solution for Payments is currently unavailable. Try again later. (ACC-909)",
    "com.desjardins.sommaire_affaires.detention.site_externe.erreur.mgps.POR.000105": "Desjardins Global Solution for Payments is currently unavailable. Try again later. (ACC-910)",
    "com.desjardins.sommaire_affaires.detention.scanneur_depot_distance.ncr.sso.compte.nonexistant": "This user profile does not have permission to use Remote Deposit Capture. (ACC-911)",
    "com.desjardins.sommaire_affaires.detail.dernieres_operations.erreur_chargement": "Remote Deposit Capture is currently unavailable. Try again later. (ACC-912)",
    GENERIC: "Something went wrong. Try again later. If the issue persists, contact us."
};
