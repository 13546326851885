import { ConfigurationService } from "@oel/js-configuration";

export class GabaritNavigationUtil {
    private static estSessionLegacyCharge = false;
    private static estAfficherMenuDroite = true;
    private static estAfficherTiroirPrimeurs = true;

    static obtenirConfigurationGabarit() {
        return {
            typeGabarit: 'consultation',
            consultation: {
                filAriane: [],
                afficherAideContact: true,
                afficherSondageCaisse: true,
                afficherLogoAssuranceDepot: true,
                afficherMenuDroite: this.estAfficherMenuDroite,
                estSessionLegacyCharge: this.estSessionLegacyCharge,
                afficherTiroirPrimeurs: this.estAfficherTiroirPrimeurs,
                idContenuPrincipal: "hub-detention-presentation-zone-centrale",
                entetePage: {
                    identifiantMenu: this.obtenirIdentifiantMenu(),
                },
                idsEnvoyerEvenement: ["accueil", "recherche-transaction"],
                estContexteN3: false,
            },
        };
    }

    private static obtenirIdentifiantMenu() {
        return ConfigurationService.getConfig("oel-contexte-applicatif") === "ADP"
            ? "entete-consultation-adp-principal-accueil"
            : undefined;
    }

    static afficherMenuDroite(afficher: boolean) {
        if (this.estAfficherMenuDroite !== afficher) {
            this.estAfficherMenuDroite = afficher;
            dispatchEvent(
                new CustomEvent("@navigation-gabarit/v1/appliquer-configuration", {
                    detail: {
                        consultation: {
                            afficherMenuDroite: afficher
                        }
                    }
                })
            );
        }
    }

    static afficherTiroirPrimeurs(afficher: boolean) {
        if (this.estAfficherTiroirPrimeurs !== afficher) {
            this.estAfficherTiroirPrimeurs = afficher;
            dispatchEvent(
                new CustomEvent("@navigation-gabarit/v1/appliquer-configuration", {
                    detail: {
                        consultation: {
                            afficherTiroirPrimeurs: afficher
                        }
                    }
                })
            );
        }
    }

    static declencherSessionLegacyCharge() {
        if (!this.estSessionLegacyCharge) {
            this.estSessionLegacyCharge = true;
            dispatchEvent(
                new CustomEvent("@navigation-gabarit/v1/appliquer-configuration", {
                    detail: {
                        consultation: {
                            estSessionLegacyCharge: this.estSessionLegacyCharge
                        }
                    }
                })
            );
        }
    }

    static reinitialiserFilAriane() {
        dispatchEvent(
            new CustomEvent("@navigation-gabarit/v1/appliquer-configuration", {
                detail: {
                    consultation: {
                        filAriane: []
                    }
                }
            })
        );
    }
}
