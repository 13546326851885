import * as bc from "bc-js";
import * as navigation from "@oel/js-navigation";

import { Alerte } from "../../commun/Alerte/Alerte";
import { GabaritNavigationUtil } from '../../../utils/GabaritNavigationUtil';
import { LangueUtils } from "@oel/js-i18n";
import { ResilienceUtil } from "../../../utils/ResilienceUtil";
import { en } from './i18n/AccueilAffaires.i18n.en';
import { fr } from './i18n/AccueilAffaires.i18n.fr';
import { useEffect } from 'react';

export function AccueilAffaires() {
    const libelles = new LangueUtils(fr, en).obtenirLibelles();

    useEffect(() => {
        document.title = libelles.titreOnglet;

        ResilienceUtil.rendreResilientSiProbleme("bc", () => bc.changerContexte("PRODUCT_Summary"));

        gererNavInitial();
        GabaritNavigationUtil.afficherMenuDroite(true);
        GabaritNavigationUtil.afficherTiroirPrimeurs(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function gererNavInitial() {
        const windowLocationSearch = window.location.search;
        navigation.ModaleService.gererOuvertureModale(windowLocationSearch);
    }

    return (
        <div className="accueil-affaires">
            <h1 className="dsd-sr-only" tabIndex={-1}>{libelles.titre}</h1>
            <accueil-affaires>
                <div slot="alertes-generiques"><Alerte/></div>
            </accueil-affaires>
        </div>
    );
}
